import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import axios from "axios"
import moment from "moment"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
  Breadcrumb,
  Typography,
  Button,
  Form,
  Input,
  Divider,
  Select,
  Radio,
  DatePicker,
  Modal,
  AutoComplete,
  InputNumber,
  Alert,
} from "antd"

import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"

const { Option } = Select
const { Title, Text } = Typography
const { Search } = Input

const kategoriPesertaData = [
  "Alumni/Awardee LPDP",
  "Mahasiswa/Umum",
  "SMA/SMK/MA/Sederajat",
]

const jenjangPendidikanData = [
  "SD",
  "SMP",
  "SMA/SMK/MA/Sederajat",
  "D1",
  "D2",
  "D3",
  "S1",
  "S2",
  "S3",
  "Lainnya",
]

const mahasiswaUmumData = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "Umum",
]

const tracingKegiatanData = [
  "Bekerja",
  "Wirausaha/Entrepreneur",
  "Melanjutkan Studi S3",
  "Magang/Internship",
  "Postdoctoral",
  "Belum Bekerja/Masih dalam Masa Pencairan Kerja",
]

const tracingBidangPekerjaanData = [
  "Publik/PNS/Kementerian/Pemerintah",
  "BUMN/BUMD",
  "Perusahaan/Instansi Swasta Lokal",
  "Perusahaan/Instansi Swasta Asing",
  "TNI/POLRI",
  "Lainnya",
]

const tracingTingkatJabatanData = [
  "CEO/GM/Director/Senior Manager Jobs (setara Eselon I, Direktur, CEO)",
  "Manager/Assistant Manager Jobs (setara Eselon I, Manager, Kepala Divisi",
  "Supervisor/Coordinator Jobs (setara Eselon III, Eselon IV, Supervisor)",
  "Staff Jobs/non-management & non-supervisor (setara Pelaksana, Fungsional, Dosen)",
  "Non-staff",
]

const tracingPendapatanData = [
  "< 36.000.000",
  "36.000.000 - 100.000.000",
  "100.000.000 - 180.000.000",
  "180.000.000 - 360.000.000",
  "360.000.000 - 600.000.000",
  "> 600.000.000",
]

const tracingBidangUsahaData = [
  "Desain & Komunikasi",
  "Energi",
  "Farmasi",
  "Hiburan/Entertainment",
  "Informasi & Teknologi",
  "Kesehatan",
  "Konstruksi",
  "Makanan dan/atau Minuman",
  "Pertanian",
  "Pendidikan",
  "Peralatan Elektronik",
  "Media",
  "Musik",
  "Pariwisata",
  "Pengolahan/Manufaktur",
  "Tambang",
  "Telekomunikasi",
  "Transportasi",
  "Lainnya",
]

const tracingPeranUsahaData = [
  "Penemu/Pengagas/Pemilik/Founder/CEO",
  "Co-Founder",
  "Partner Bisnis",
  "Pemberi Modal Usaha/Venture Capital",
]

const tracingJumlahPegawaiUsahaData = [
  "1 - 5 orang",
  "6 - 25 orang",
  "26 - 50 orang",
  "51 - 100 orang",
  "101 - 500 orang",
  "501 - 1000 orang",
  "> 1000 orang",
]

const tracingOmsetUsahaData = [
  "1 - 50 juta",
  "51 - 100 juta",
  "101 - 500 juta",
  "501 - 1 M",
  "1 - 5 M",
  "5 - 10 M",
  "> 10 M",
]

const tracingProfitUsahaData = [
  "< 0% (Rugi)",
  "0 - 10 %",
  "10 - 30 %",
  "30 - 50 %",
  "50 - 75%",
  "75 - 100 %",
  "> 100 %",
]

const tracingHambatanCariKerjaData = [
  "Persaingan dengan kandidat lain",
  "Pekerjaan yang dicari tidak sesuai dengan latar belakang pendidikan",
  "Pekerjaan yang dicari tidak ada dan tidak sesuai dengan latar belakang pendidikan",
  "Saya tidak tahu harus mencari pekerjaan seperti apa dengan latar belakang pendidikan saya",
  "Saya tidak yakin dengan kemampuan saya",
  "Saya kurang komunikatif dalam menceritakan kemampuan saya dalam proses interview",
  "Saya kurang memahami deskripsi pekerjaan yang saya lamar",
  "Kompensasi yang ditawarkan kurang menarik",
  "Saya tidak memiliki kendala apapun",
  "Lainnya",
]

const tracingBentukPengembanganDiriData = [
  "Coaching",
  "Monitoring",
  "Training/Workshop",
  "Konsultasi",
  "Lainnya",
]

const smaData = ["10", "11", "12"]

const TahapSatuPage = () => {
  const { t } = useI18next()

  const [loading, setLoading] = useState(false)

  const [participantCategory, setParticipantCategory] =
    useState(kategoriPesertaData)

  const [educationLevel, setEducationLevel] = useState([])

  // const [hiddenForm, setHiddenForm] = useState(false)

  const [isBGP, setIsBGP] = useState(false)

  const [isMahasiswaUmum, setIsMahasiswaUmum] = useState(false)

  const [isNotAwardee, setIsNotAwardee] = useState(false)

  const [tingkat, setTingkat] = useState([])

  const [provinceData, setProvinceData] = useState([])

  const [cityData, setCityData] = useState([])

  const [filteredCityData, setFilteredCityData] = useState([])

  const [regionalCode, setRegionalCode] = useState("")

  const [birthDate, setBirthDate] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isDirectLink, setIsDirectLink] = useState(false)

  const [modalTitle, setModalTitle] = useState("")

  const [modalDescription, setModalDescription] = useState("")

  const [form] = Form.useForm()

  const [isRegistrationClose, setIsRegistrationClose] = useState(false)

  const [searchStatus, setSearchStatus] = useState("")

  const [isSearchSubmitting, setIsSearchSubmitting] = useState(false)

  const [temaBisnis, setTemaBisnis] = useState([])

  const [showVerificationInputs, setShowVerificationInputs] = useState(false)

  const [showDataDiriInputs, setShowDataDiriInputs] = useState(false)

  const [showTracerAlumniInputs, setShowTracerAlumniInputs] = useState(false)

  const [showDataBisnisInputs, setShowDataBisnisInputs] = useState(false)

  const [showBekerjaInputs, setShowBekerjaInputs] = useState(false)

  const [showPengusahaInputs, setShowPengusahaInputs] = useState(false)

  const [showStudiS3Inputs, setShowStudiS3Inputs] = useState(false)

  const [showMagangInputs, setShowMagangInputs] = useState(false)

  const [showPostdoctoralInputs, setShowPostdoctoralInputs] = useState(false)

  const [showBelumBekerjaInputs, setShowBelumBekerjaInputs] = useState(false)

  const [tracerStudiMandiri, setTracerStudiMandiri] = useState(false)

  const [tracerStartStudiDate, setTracerStartStudiDate] = useState(null)

  const [tracerEndStudiDate, setTracerEndStudiDate] = useState(null)

  const [tracerStartMagangDate, setTracerStartMagangDate] = useState(null)

  const [tracerEndMagangDate, setTracerEndMagangDate] = useState(null)

  const [tracerStartPostdoctoralDate, setTracerStartPostdoctoralDate] =
    useState(null)

  const [tracerEndPostdoctoralDate, setTracerEndPostdoctoralDate] =
    useState(null)

  const [showTracerPengembanganInput, setShowTracerPengembanganInput] =
    useState(false)

  const formConfig = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
    size: "large",
    layout: "vertical",
    scrollToFirstError: true,
    autoComplete: "off",
    className: "flat-form",
    initialValues: {
      jenis_identitas: "ktp",
      jenis_kelamin: "L",
    },
  }

  const handleCompetitionCategoryChange = event => {
    const value = event.target.value

    if (value === "BIP") {
      setParticipantCategory(kategoriPesertaData)

      setIsBGP(false)
    } else {
      const filteredKategoriPesertaData = kategoriPesertaData.filter(
        kategori => {
          return (
            kategori === "Alumni/Awardee LPDP" || kategori === "Mahasiswa/Umum"
          )
        }
      )

      setParticipantCategory(filteredKategoriPesertaData)

      setIsBGP(true)
    }

    // if (value !== null) {
    //   setHiddenForm(true)
    // }

    form.setFieldsValue({ kategori_peserta: null })
  }

  const handleParticipantCategoryChange = value => {
    const filteredHighSchoolEducationLevel = jenjangPendidikanData.filter(
      edu => {
        return edu !== "SD" && edu !== "SMP"
      }
    )

    if (value === "SMA/SMK/MA/Sederajat" && !isBGP) {
      setEducationLevel(jenjangPendidikanData)
    }

    if (value !== "SMA/SMK/MA/Sederajat" && !isBGP) {
      setEducationLevel(filteredHighSchoolEducationLevel)
    }

    if (isBGP) {
      setEducationLevel(filteredHighSchoolEducationLevel)
    }

    if (value === "Mahasiswa/Umum") {
      setIsMahasiswaUmum(true)
      setTingkat(mahasiswaUmumData)
    } else {
      setIsMahasiswaUmum(false)
      setTingkat(smaData)
    }

    setIsNotAwardee(value !== "Alumni/Awardee LPDP")
  }

  const handleTanggalLahirChange = (date, dateString) => {
    setBirthDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleProvinceSelect = (value, option) => {
    setRegionalCode(option.kode_wilayah)

    const filteredCityData = cityData.filter(city => {
      return (
        city.kode_provinsi === option.kode_provinsi &&
        city.value !== option.value
      )
    })

    setFilteredCityData(filteredCityData)

    form.setFieldsValue({ wilayah: option.kode_wilayah })
  }

  const onFinish = async values => {
    const date = birthDate ? moment(birthDate).format("YYYY/MM/DD") : ""
    const startStudiDate = tracerStartStudiDate
      ? moment(tracerStartStudiDate).format("YYYY/MM/DD")
      : ""
    const endStudiDate = tracerEndStudiDate
      ? moment(tracerEndStudiDate).format("YYYY/MM/DD")
      : ""
    const startMagangDate = tracerStartMagangDate
      ? moment(tracerStartMagangDate).format("YYYY/MM/DD")
      : ""
    const endMagangDate = tracerStartMagangDate
      ? moment(tracerEndMagangDate).format("YYYY/MM/DD")
      : ""
    const startPostdoctoralDate = tracerStartPostdoctoralDate
      ? moment(tracerStartPostdoctoralDate).format("YYYY/MM/DD")
      : ""
    const endPostdoctoralDate = tracerEndPostdoctoralDate
      ? moment(tracerEndPostdoctoralDate).format("YYYY/MM/DD")
      : ""

    const data = {
      ...values,
      tanggal_lahir: date,
      telepon_pribadi: `62${values.telepon_pribadi}`,
      start_date_studi_lanjutan: startStudiDate,
      end_date_studi_lanjutan: endStudiDate,
      start_date_magang: startMagangDate,
      end_date_magang: endMagangDate,
      start_date_postdoctoral: startPostdoctoralDate,
      end_date_postdoctoral: endPostdoctoralDate,
    }

    // setIsModalOpen(true)

    setLoading(true)

    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/frontend/scholarship/business-competition/storeTahap1`,
        data
      )

      const responseCode = response.data.code

      if (responseCode === 200) {
        setModalTitle("Terimakasih atas Partisipasi Anda")
        setModalDescription(
          "Pendaftaran kompetisi 5ME2045 anda sudah berhasil terkirim. Silakan cek email untuk petunjuk selanjutnya."
        )

        setIsDirectLink(true)
      }

      if (responseCode === 400) {
        setModalTitle("Terjadi Masalah Saat Proses")
        setModalDescription(
          "Ada input data yang perlu diisi, silahkan lengkapi terlebih dahulu"
        )

        if (response.data.errors.email) {
          setModalDescription("Email sudah pernah didaftarkan!")
        }

        setIsDirectLink(false)
      }

      if (responseCode === 500) {
        setModalTitle("Terjadi Masalah Saat Proses")
        setModalDescription(
          "Silahkan hubungi call center kami di 134 atau +62-21-23507011 untuk bantuan kendala anda."
        )

        setIsDirectLink(false)
      }

      if (responseCode === 403) {
        setModalTitle("Terjadi Masalah Saat Proses")
        setModalDescription(response.data.errors)

        setIsDirectLink(false)
      }

      if (isDirectLink) {
        form.resetFields()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsModalOpen(true)
      setLoading(false)
      setSearchStatus("")
    }
  }

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
  }

  const handleOk = () => {
    setIsModalOpen(false)

    if (isDirectLink) {
      window.location.href = "https://lpdp.kemenkeu.go.id"
    }
  }

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/frontend/scholarship/business-competition/getProvinsiWilayahKota`
      )

      const data = await response.json()

      const mappedProvinceData = data.data.provinsiWilayah.map(prov => ({
        value: prov.nama_provinsi,
        id: prov.id,
        kode_provinsi: prov.kode_provinsi,
        nama_provinsi: prov.nama_provinsi,
        kode_wilayah: prov.kode_wilayah,
      }))

      const mappedCityData = data.data.kota.map(city => ({
        id: city.id,
        value: city.nama_kabkota,
        kode_provinsi: city.kode_provinsi,
        kode_kabkota: city.kode_kabkota,
        nama_kabkota: city.nama_kabkota,
      }))

      setProvinceData(mappedProvinceData)

      setCityData(mappedCityData)

      setIsRegistrationClose(true)
    }

    async function fetchTemaBisnis() {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/frontend/scholarship/business-competition/getTemaBisnis`
      )

      const data = await response.json()

      const mappedData = data.map(item => ({
        label: item.tema_bisnis,
        value: item.tema_bisnis,
      }))

      setTemaBisnis(mappedData)
    }

    fetchData()

    fetchTemaBisnis()
  }, [])

  const kategoriKompetisiValue = Form.useWatch("kategori_kompetisi", form)

  const kategoriPesertaValue = Form.useWatch("kategori_peserta", form)

  useEffect(() => {
    setShowVerificationInputs(kategoriKompetisiValue && kategoriPesertaValue)

    setShowDataDiriInputs(kategoriKompetisiValue && kategoriPesertaValue)

    if (kategoriPesertaValue !== "Alumni/Awardee LPDP") {
      form.setFieldsValue({
        nomor_induk_beasiswa: null,
        jenis_beasiswa: null,
        universitas_tujuan_pendaftaran: null,
        tahun_kontrak: null,
      })

      setSearchStatus("")
    }
  }, [kategoriKompetisiValue, kategoriPesertaValue])

  const emailValue = Form.useWatch("email", form)

  const namaLengkapValue = Form.useWatch("nama_lengkap", form)

  const jenisIdentitasValue = Form.useWatch("jenis_identitas", form)

  const nomorIdentitasValue = Form.useWatch("nomor_identitas", form)

  const jenisKelaminValue = Form.useWatch("jenis_kelamin", form)

  const tanggalLahirValue = Form.useWatch("tanggal_lahir", form)

  const provinsiValue = Form.useWatch("provinsi", form)

  const kotaValue = Form.useWatch("kota", form)

  const teleponPribadiValue = Form.useWatch("telepon_pribadi", form)

  const pendidikanTerakhirValue = Form.useWatch("pendidikan_terakhir", form)

  useEffect(() => {
    if (
      emailValue &&
      namaLengkapValue &&
      jenisIdentitasValue &&
      nomorIdentitasValue &&
      jenisKelaminValue &&
      tanggalLahirValue &&
      provinsiValue &&
      kotaValue &&
      teleponPribadiValue &&
      pendidikanTerakhirValue
    ) {
      setShowTracerAlumniInputs(true)
      setShowDataBisnisInputs(true)
    } else {
      setShowTracerAlumniInputs(false)
      setShowDataBisnisInputs(false)
    }
  }, [
    emailValue,
    namaLengkapValue,
    jenisIdentitasValue,
    nomorIdentitasValue,
    jenisKelaminValue,
    tanggalLahirValue,
    provinsiValue,
    kotaValue,
    teleponPribadiValue,
    pendidikanTerakhirValue,
  ])

  const handleSearch = async value => {
    try {
      setIsSearchSubmitting(true)

      setSearchStatus("")

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/frontend/scholarship/business-competition/checkAwardeeBc`,
        { no_induk_lpdp: value }
      )

      const data = response.data

      if (data.code) {
        setSearchStatus("error")

        return
      }

      setSearchStatus("success")

      setBirthDate(moment(data.tanggal_lahir))

      form.setFieldsValue({
        nomor_induk_beasiswa: value,
        nama_lengkap: data.nama_lengkap,
        jenis_identitas: "KTP",
        nomor_identitas: data.NIK,
        jenis_kelamin: data.jenis_kelamin === "Perempuan" ? "P" : "L",
        tanggal_lahir: moment(data.tanggal_lahir),
        jenis_beasiswa: data.jenis_beasiswa,
        universitas_tujuan_pendaftaran: data.universitas_tujuan_kontrak,
        tahun_kontrak: data.tahun_kontrak,
      })
    } catch (error) {
      setSearchStatus("error")
      console.error("Error:", error)
    } finally {
      setIsSearchSubmitting(false)
    }
  }

  const noTelpHandler = value => {
    form.setFieldsValue({ telepon_pribadi: value })
  }

  const emailHandler = event => {
    const value = event.target.value

    form.setFieldsValue({ email: value })
  }

  const handleTracerKegiatanChange = value => {
    setShowBekerjaInputs(value === "Bekerja")

    setShowBelumBekerjaInputs(
      value === "Belum Bekerja/Masih dalam Masa Pencairan Kerja"
    )

    setShowMagangInputs(value === "Magang/Internship")

    setShowPengusahaInputs(value === "Wirausaha/Entrepreneur")

    setShowPostdoctoralInputs(value === "Postdoctoral")

    setShowStudiS3Inputs(value === "Melanjutkan Studi S3")
  }

  const handleTracerPembiayaanStudiChange = e => {
    const value = e.target.value

    setTracerStudiMandiri(value === "Mandiri/Non-LPDP")
  }

  const handleStartStudiChange = (date, dateString) => {
    setTracerStartStudiDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleEndStudiChange = (date, dateString) => {
    setTracerEndStudiDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleTracerStartMagangChange = (date, dateString) => {
    setTracerStartMagangDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleTracerEndMagangChange = (date, dateString) => {
    setTracerEndMagangDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleTracerStartPostdoctoralChange = (date, dateString) => {
    setTracerStartPostdoctoralDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleTracerEndPostdoctoralChange = (date, dateString) => {
    setTracerEndPostdoctoralDate(moment(dateString, "YYYY/MM/DD"))
  }

  const handleTracerPengembanganDiriChange = e => {
    const value = e.target.value

    setShowTracerPengembanganInput(value === "Ya")
  }

  return (
    <Layout module_name="scholarship" page_name="registration-form">
      {isRegistrationClose && (
        <React.Fragment>
          <Seo
            title="Form Pendaftaran Business Competition"
            module_name="scholarship"
            page_name="registration-form"
          />
          <Container>
            <Breadcrumb separator={<ChevronRight />}>
              <Breadcrumb.Item>
                <Link to="/">{t("Beranda")}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t("Form Pendaftaran Business Competition")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Container>

          <Container>
            <Title>{t("Form Pendaftaran - Tahap 1")}</Title>

            <Title level={5}>
              Unduh buku panduan peserta dan registrasi{" "}
              <Typography.Link href="https://drive.google.com/drive/folders/1y8n1CPCVD3KUJZXwSpDlWrC5-C6y4E_u?usp=sharing">
                disini
              </Typography.Link>
              .
            </Title>

            <Form
              {...formConfig}
              form={form}
              name="pendaftaran"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {/* Kategori bisnis */}
              <Divider orientation="left">
                <Title level={5}>Kategori Bisnis</Title>
              </Divider>

              <Form.Item
                name="kategori_kompetisi"
                label="Kategori Kompetisi"
                rules={[{ required: true }]}
              >
                <Radio.Group onChange={handleCompetitionCategoryChange}>
                  <Radio value="BIP">Business Initiation Plan (BIP)</Radio>
                  <Radio value="BGP">Business Growth Plan (BGP)</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="kategori_peserta"
                label="Kategori Peserta"
                rules={[{ required: true }]}
              >
                <Select
                  options={participantCategory.map(kategori => ({
                    label: kategori,
                    value: kategori,
                  }))}
                  onChange={handleParticipantCategoryChange}
                  placeholder={t("Pilih salah satu")}
                  allowClear
                />
              </Form.Item>

              {/* Verifikasi */}
              {showVerificationInputs && !isNotAwardee && (
                <React.Fragment>
                  <Divider orientation="left">
                    <Title level={5}>Verifikasi</Title>
                  </Divider>

                  <Form.Item
                    name="nomor_induk_beasiswa"
                    label="Nomor Induk LPDP"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Nomor Induk LPDP!",
                      },
                    ]}
                  >
                    <Search
                      onSearch={handleSearch}
                      placeholder="Masukkan Nomor Induk LPDP"
                      loading={isSearchSubmitting}
                      enterButton
                    />

                    {searchStatus === "success" && (
                      <Alert message="Data valid" type="success" showIcon />
                    )}
                    {searchStatus === "error" && (
                      <Alert
                        message="Data tidak ditemukan!"
                        type="error"
                        showIcon
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    label="Jenis Beasiswa"
                    name="jenis_beasiswa"
                    rules={[
                      {
                        required: true,
                        message: "Please input type of scholarship",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Kampus Tujuan"
                    name="universitas_tujuan_pendaftaran"
                    rules={[
                      {
                        required: true,
                        message: "Please input destination university",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Tahun Kontrak"
                    name="tahun_kontrak"
                    rules={[
                      { required: true, message: "Please input contract year" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </React.Fragment>
              )}

              {/* Data diri */}
              {showDataDiriInputs && (
                <React.Fragment>
                  <Divider orientation="left">
                    <Title level={5}>Data Diri</Title>
                  </Divider>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: t("Silahkan masukan email yang valid"),
                      },
                      {
                        required: true,
                        message: t("Silakan masukan email Anda!"),
                      },
                    ]}
                  >
                    <Text>{"Tidak disarankan menggunakan email instansi"}</Text>

                    <Input
                      placeholder={t(
                        "Input alamat email dari founder yang aktif dan dapat dihubungi"
                      )}
                      onChange={emailHandler}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nama Lengkap"
                    name="nama_lengkap"
                    rules={[
                      {
                        required: true,
                        message: "Please input your full name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        "Input nama lengkap founder sesuai identitas diri yang di upload"
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    name="jenis_identitas"
                    label="Jenis Identitas"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder={t("Pilih salah satu")} allowClear>
                      <Option value="ktp">KTP</Option>
                      <Option value="sim">SIM</Option>
                      <Option value="passport">Passport</Option>
                      <Option value="passport">Nomor Identitas Siswa</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Nomor Identitas"
                    name="nomor_identitas"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Identity ID!",
                      },
                    ]}
                  >
                    <Input placeholder={t("Input nomor identitas")} />
                  </Form.Item>

                  <Form.Item
                    label="Jenis Kelamin"
                    name="jenis_kelamin"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group>
                      <Radio value="L">Laki-laki</Radio>
                      <Radio value="P">Perempuan</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Tanggal Lahir"
                    name="tanggal_lahir"
                    rules={[
                      {
                        required: true,
                        message: "Please input your birth date!",
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={handleTanggalLahirChange}
                      format="YYYY/MM/DD"
                      style={{ width: "100%" }}
                      placeholder={t(
                        "Input tanggal lahir sesuai dokumen identitas"
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Provinsi"
                    name="provinsi"
                    rules={[
                      {
                        required: true,
                        message: "Please input your province!",
                      },
                    ]}
                  >
                    <AutoComplete
                      options={provinceData}
                      onSelect={handleProvinceSelect}
                      placeholder="Cari Provinsi"
                    >
                      <Search enterButton />
                    </AutoComplete>
                  </Form.Item>

                  <Form.Item
                    label="Wilayah"
                    name="wilayah"
                    rules={[
                      { required: true, message: "Please input your region!" },
                    ]}
                  >
                    <Input value={regionalCode} disabled />
                  </Form.Item>

                  <Form.Item
                    label="Kota"
                    name="kota"
                    rules={[
                      { required: true, message: "Please input your city!" },
                    ]}
                  >
                    <AutoComplete
                      options={filteredCityData}
                      placeholder="Cari Kota"
                    >
                      <Search enterButton />
                    </AutoComplete>
                  </Form.Item>

                  <Form.Item label="Kode Pos" name="kode_pos">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Telepon Pribadi (HP)"
                    name="telepon_pribadi"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Text>{"Masukkan nomor Whatsapp aktif"}</Text>

                    <InputNumber
                      prefix="+62"
                      style={{ width: "100%" }}
                      placeholder={t(
                        "Input nomor telepon pribadi yang dapat dihubungi oleh panitia"
                      )}
                      onChange={noTelpHandler}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nomor lain yang bisa dihubungi"
                    name="telepon_rumah"
                  >
                    <Input placeholder="Input nomor lain yang bisa dihubungi" />
                  </Form.Item>

                  <Form.Item
                    label="Pendidikan Terakhir (telah mendapat ijazah)"
                    name="pendidikan_terakhir"
                    rules={[
                      {
                        required: true,
                        message: "Please input your graduate!",
                      },
                    ]}
                  >
                    <Select
                      options={educationLevel.map(pendidikan => ({
                        label: pendidikan,
                        value: pendidikan,
                      }))}
                      placeholder={t("Pilih salah satu")}
                      allowClear
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {/* Tracing Alumni */}
              {showTracerAlumniInputs && !isNotAwardee && (
                <React.Fragment>
                  <Divider orientation="left">
                    <Title level={5}>Tracing Data Alumni</Title>
                  </Divider>

                  <Form.Item
                    name="kegiatan_saat_ini"
                    label="Apa kegiatan Anda saat ini?"
                    rules={[
                      {
                        required: true,
                        message: "Please input your current activity",
                      },
                    ]}
                  >
                    <Select
                      options={tracingKegiatanData.map(item => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={handleTracerKegiatanChange}
                      placeholder={t("Pilih salah satu")}
                      allowClear
                    />
                  </Form.Item>

                  {/* Bekerja */}
                  {showBekerjaInputs && (
                    <React.Fragment>
                      <Form.Item
                        name="nama_instansi"
                        label="Nama perusahaan/instansi tempat Anda bekerja saat ini"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="bidang_pekerjaan"
                        label="Kategori jenis perusahaan/instansi tempat Anda bekerja saat ini"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingBidangPekerjaanData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="nama_jabatan"
                        label="Nama jabatan Anda saat ini"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="tingkat_jabatan"
                        label="Tingkat jabatan Anda saat ini"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingTingkatJabatanData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="manfaat_beasiswa_peningkatan_jabatan"
                        label="Apakah Anda mengalami peningkatan jabatan setelah menempuh studi dengan pembiayaan dari beasiswa LPDP? "
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <Radio value="Ya">Ya</Radio>
                          <Radio value="Tidak">Tidak</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="pendapatan_per_tahun"
                        label="Dengan mempertimbangkan seluruh pendapatan Anda dalam satu tahun, berapa range pendapatan Anda saat ini?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingPendapatanData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}

                  {/* Pengusaha */}
                  {showPengusahaInputs && (
                    <React.Fragment>
                      <Form.Item
                        name="nama_instansi_sendiri"
                        label="Apa nama bisnis atau usaha yang Anda dirikan?"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="bidang_usaha_sendiri"
                        label="Bisnis atau usaha Anda bergerak di bidang apa?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingBidangUsahaData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="peran_usaha_sendiri"
                        label="Apa peran Anda dalam membangun bisnis/usaha tersebut?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingPeranUsahaData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="jumlah_pegawai_usaha_sendiri"
                        label="Berapa jumlah karyawan yang bekerja pada bisnis/usaha Anda saat ini?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingJumlahPegawaiUsahaData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="omset_bisnis"
                        label="Berapakah kisaran revenue/omzet bisnis Anda dalam satu tahun terakhir?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingOmsetUsahaData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          // onChange={handleParticipantCategoryChange}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="profit_bisnis"
                        label="Berapa profit margin Anda dalam satu tahun terakhir?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingProfitUsahaData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="jenis_bisnis"
                        label="Apa jenis bisnis/usaha yang Anda dirikan?"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <Radio value="Jasa/Pelayanan/Konsultasi">
                            Jasa/Pelayanan/Konsultasi
                          </Radio>
                          <Radio value="Produk/Barang">Produk/Barang</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="tipe_bisnis"
                        label="Apa tipe bisnis/usaha yang Anda dirikan?"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <Radio value="Bisnis Komersial">
                            Bisnis Komersial
                          </Radio>
                          <Radio value="Bisnis Sosial">Bisnis Sosial</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="sosial_media_usaha_sendiri"
                        label="Mohon untuk menuliskan link website atau media social dari bisnis/usaha Anda"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  )}

                  {/* Studi s3 */}
                  {showStudiS3Inputs && (
                    <React.Fragment>
                      <Form.Item
                        name="jenis_studi_lanjutan"
                        label="Pembiayaan studi lanjutan S3"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group
                          onChange={handleTracerPembiayaanStudiChange}
                        >
                          <Radio value="Mandiri/Non-LPDP">
                            Mandiri/Non-LPDP
                          </Radio>
                          <Radio value="Beasiswa LPDP">Beasiswa LPDP</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* Non-LPDP */}
                      {tracerStudiMandiri && (
                        <React.Fragment>
                          <Form.Item
                            name="persetujuan_studi_lanjutan"
                            label="Apakah Anda sudah mengajukan permohonan izin studi lanjutan kepada LPDP?"
                            rules={[{ required: true }]}
                          >
                            <Radio.Group>
                              <Radio value="Sudah">Sudah</Radio>
                              <Radio value="Belum">Belum</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <Form.Item
                            name="universitas_tujuan_studi_lanjutan"
                            label="Universitas tujuan studi lanjutan"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name="program_studi_lanjutan"
                            label="Program studi"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name="negara_tujuan_lanjutan"
                            label="Negara tujuan studi"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name="start_date_studi_lanjutan"
                            label="Mulai studi"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              onChange={handleStartStudiChange}
                              format="YYYY/MM/DD"
                              style={{ width: "100%" }}
                              placeholder={t("Input tanggal mulai studi")}
                            />
                          </Form.Item>

                          <Form.Item
                            name="end_date_studi_lanjutan"
                            label="Selesai studi"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              onChange={handleEndStudiChange}
                              format="YYYY/MM/DD"
                              style={{ width: "100%" }}
                              placeholder={t("Input tanggal akhir studi")}
                            />
                          </Form.Item>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  {/* Magang */}
                  {showMagangInputs && (
                    <React.Fragment>
                      <Form.Item
                        name="sudah_mengajukan_izin_magang"
                        label="Apakah Anda sudah mengajukan permohonan izin magang pascastudi kepada LPDP?"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <Radio value="Sudah">Sudah</Radio>
                          <Radio value="Belum">Belum</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="instansi_magang"
                        label="Nama perusahaan/instansi tempat magang pascastudi"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="jabatan_magang"
                        label="Jabatan/posisi selama magang pascastudi"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="negara_tempat_magang"
                        label="Negara tempat magang pascastudi"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="start_date_magang"
                        label="Mulai magang"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          onChange={handleTracerStartMagangChange}
                          format="YYYY/MM/DD"
                          style={{ width: "100%" }}
                          placeholder={t("Input tanggal mulai magang")}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date_magang"
                        label="Selesai magang"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          onChange={handleTracerEndMagangChange}
                          format="YYYY/MM/DD"
                          style={{ width: "100%" }}
                          placeholder={t("Input tanggal akhir magang")}
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}

                  {/* Postdoctoral */}
                  {showPostdoctoralInputs && (
                    <React.Fragment>
                      <Form.Item
                        name="sudah_mengajukan_izin_postdoctoral"
                        label="Apakah Anda sudah mengajukan permohonan izin postdoctoral kepada LPDP?"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <Radio value="Sudah">Sudah</Radio>
                          <Radio value="Belum">Belum</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="universitas_tujuan_postdoctoral"
                        label="Universitas/instansi tujuan postdoctoral"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="program_studi_postdoctoral"
                        label="Jenis program/riset/studi postdoctoral"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="jabatan_selama_postdoctoral"
                        label="Jabatan/posisi selama postdoctoral"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="negara_tujuan_postdoctoral"
                        label="Negara tujuan postdoctoral"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="start_date_postdoctoral"
                        label="Mulai studi postdoctoral"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          onChange={handleTracerStartPostdoctoralChange}
                          format="YYYY/MM/DD"
                          style={{ width: "100%" }}
                          placeholder={t("Input tanggal mulai postdoctoral")}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date_postdoctoral"
                        label="Selesai studi postdoctoral"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          onChange={handleTracerEndPostdoctoralChange}
                          format="YYYY/MM/DD"
                          style={{ width: "100%" }}
                          placeholder={t("Input tanggal akhir postdoctoral")}
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}

                  {/* Belum bekerja */}
                  {showBelumBekerjaInputs && (
                    <React.Fragment>
                      <Form.Item
                        name="hambatan_mencari_pekerjaan"
                        label="Apa faktor yang mempengaruhi Anda sehingga Anda belum bekerja saat ini?"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tracingHambatanCariKerjaData.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>

                      <Form.Item
                        name="is_butuh_pengembangan_diri"
                        label="Apakah Anda membutuhkan kegiatan pengembangan diri untuk menunjang rencana karir Anda?"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group
                          onChange={handleTracerPengembanganDiriChange}
                        >
                          <Radio value="Ya">Ya</Radio>
                          <Radio value="Tidak">Tidak</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* Ya pengembangan */}
                      {showTracerPengembanganInput && (
                        <Form.Item
                          name="kegiatan_pengembangan_diri_yang_dibutuhkan"
                          label="Bentuk kegiatan pengembangan diri seperti apakah yang Anda butuhkan?"
                          rules={[{ required: true }]}
                        >
                          <Select
                            options={tracingBentukPengembanganDiriData.map(
                              item => ({
                                label: item,
                                value: item,
                              })
                            )}
                            placeholder={t("Pilih salah satu")}
                            allowClear
                          />
                        </Form.Item>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {/* Data bisnis */}
              {showDataBisnisInputs && (
                <React.Fragment>
                  <Divider orientation="left">
                    <Title level={5}>Data Bisnis</Title>
                  </Divider>

                  {/* Is BGP */}
                  {isBGP && (
                    <Form.Item label="Nomor NPWP" name="nomor_npwp">
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={t(
                          "Input nomor NPWP Bagi yang sudah punya NPWP"
                        )}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="tema"
                    label="Tema Bisnis"
                    rules={[
                      {
                        required: true,
                        message: "Please input business category",
                      },
                    ]}
                  >
                    <Select
                      options={temaBisnis}
                      placeholder={t("Pilih salah satu")}
                      allowClear
                    />
                  </Form.Item>

                  <Form.Item
                    name="nama_bisnis"
                    label="Nama Bisnis"
                    rules={[
                      {
                        required: true,
                        message: "Please input business name!",
                      },
                    ]}
                  >
                    <Input placeholder="Input nama bisnis" />
                  </Form.Item>

                  {/* Not awardee */}
                  {isNotAwardee && (
                    <React.Fragment>
                      <Form.Item
                        name={
                          isMahasiswaUmum ? "asal_instansi" : "nama_sekolah"
                        }
                        label={
                          isMahasiswaUmum
                            ? "Asal kampus/instansi"
                            : "Asal sekolah"
                        }
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder={
                            isMahasiswaUmum
                              ? "Input asal kampus/instansi"
                              : "Input asal sekolah"
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name={isMahasiswaUmum ? "semester" : "tingkat"}
                        label={isMahasiswaUmum ? "Semester" : "Kelas"}
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={tingkat.map(item => ({
                            label: item,
                            value: item,
                          }))}
                          placeholder={t("Pilih salah satu")}
                          allowClear
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {/* Submit */}
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Form>

            <Modal
              visible={isModalOpen}
              footer={[
                <Button
                  key="ok"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                >
                  Ok
                </Button>,
              ]}
              onCancel={() => setIsModalOpen(false)}
            >
              <Title className="text-center" style={{ marginTop: 15 }}>
                {modalTitle}
              </Title>

              <Text className="text-center">{modalDescription}</Text>
            </Modal>
          </Container>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default TahapSatuPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
